import React from "react";
import { Container, Row } from "reactstrap";

// Import Section Title
import SectionTitle from "../common/section-title";

// Import Pricing
import PricingBox from "./pricing-box";

const Pricing = () => {
  const pricings = [
    {
      title: "Classic",
      price: 80,
      features: [
        { title: "1-page web design" },
        { title: "Modern user interface (UI)" },
        { title: "Free hosting included" },
        { title: "Fast and Functional" },
        { title: "Delivery in 2 days" },
      ],
    },
    {
      title: "Popular",
      price: 200,
      isNew: true,
      features: [
        { title: "3-page Web Design" },
        { title: "Modern user interface (UI)" },
        { title: "Free hosting included" },
        { title: "Fast and Functional" },
        { title: "Delivery in 5 days" },
      ],
    },
    {
      title: "Custom Website",
      price: "--",
      features: [
        { title: "Custom-pages" },
        { title: "Modern User Interface (UI)" },
        { title: "Custom Hosting /Free Hosting " },
        { title: "Fast and Functional" },
        { title: "Delivery as per Project's Need" },
      ],
    },
  ];

  return (
    <React.Fragment>
      <section className="section bg-light" id="pricing">
        <Container>
          <SectionTitle
            title="OUR PRICING"
            subtitle="OFFERS"
            desc="Transparent and flexible pricing tailored to your needs, ensuring value for investment in our top-tier services and expertise."
          />

          <Row className="mt-5 pt-2">
            <PricingBox pricings={pricings} />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Pricing;
