import React from "react";
import { Container, Row } from "reactstrap";

// Import Section Title
import SectionTitle from "../common/section-title";
import ProcessBox from "./process-box";
import ScrollAnimation from "../../utils/Animation/ScrollAnimation";

const Process = () => {
  const processesData = [
    { id: "01", title: "Concept", desc: "Ideate brilliance for groundbreaking projects." },
    { id: "02", title: "Plan", desc: "Strategize meticulously for seamless execution." },
    { id: "03", title: "Design", desc: "Craft visuals with precision and purpose." },
    { id: "04", title: "Development", desc: "Transform designs into functional digital experiences." },
  ];

  return (
    <>
      <ScrollAnimation observeClass="hiddenBox" animationClass="showBox" />
      <section className="bg-progress">
        <div className="shadow">
          <Container>
            <SectionTitle
              title="Our Process"
              desc="Discover our process: a strategic journey from ideation to execution, ensuring seamless workflows and outstanding outcomes in every endeavor"
              isLight={true}
            />

            <Row className="mt-5 pt-2">
              <ProcessBox processes={processesData} />
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default Process;
