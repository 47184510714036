import { Suspense, useState } from "react";
import NavbarPage from "../Navbar/Navbar_Page";
import Footer from "../Footer/footer";

const Layout = ({ children }) => {
  const [navItems] = useState([
    { id: 1, link: "/", navheading: "Home" },
    { id: 2, link: "/about", navheading: "About" },
    { id: 5, link: "/contact", navheading: "Contact" },
  ]);

  const Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">Loading...</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Suspense fallback={<Loader />}>
        <NavbarPage navItems={navItems} navClass={"nav-sticky"} imglight={false} />
        {children}
        <Footer />
      </Suspense>
    </>
  );
};

export default Layout;
