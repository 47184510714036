import React from "react";
import { Container } from "reactstrap";

import landing_image from "../assets/images/landing-page.png";

const Section = () => {
  return (
    <React.Fragment>
      <section className="home-section" id="home">
        <Container>
          <div className="home-hero">
            <div className="home-content text-right">
              <h2 className="title mt-4 pt-3 f-18">LEADING THE WAY IN SPEEDY DIGITAL TRANSFORMATION</h2>
              <p className="sub-title mt-4 f-18">
                At DevSiter, we specialize in crafting cutting-edge web solutions powered by the Next js and React. From
                lightning-fast front-end experiences to robust back-end functionality...
              </p>
              <div className="pt-4 mt-1">
                <a href=" https://wa.me/923111352377" rel="noreferer" target="_blank" className="btn btn-purchase">
                  Chat on WhatsApp
                </a>
              </div>
            </div>
            <div className="hero-image">
              <img alt="landing" src={landing_image} className="landing" />
            </div>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Section;
