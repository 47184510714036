import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import ScrollAnimation from "../../utils/Animation/ScrollAnimation";

const Categories = ({ setCategory, displayCategory }) => {
  return (
    <>
      <ScrollAnimation observeClass="hiddenBox" animationClass="showBox" />
      <Row className="mt-5 pt-2">
        <Col lg={12}>
          <div className="text-center">
            <ul className="col container-filter portfolioFilte list-unstyled mb-0" id="filter">
              <li className="ml-1 hiddenBox">
                <Link
                  to="#"
                  onClick={() => setCategory("All")}
                  className={`${displayCategory === "All" ? "categories active" : "categories"}`}
                >
                  All
                </Link>
              </li>
              <li className="ml-1 hiddenBox">
                <Link
                  to="#"
                  onClick={() => setCategory("Ecommerce")}
                  className={`${displayCategory === "Ecommerce" ? "categories active " : "categories"}`}
                >
                  Ecommerce
                </Link>
              </li>
              <li className="ml-1 hiddenBox">
                <Link
                  to="#"
                  onClick={() => setCategory("Education")}
                  className={`${displayCategory === "Education" ? "categories active" : "categories"} `}
                >
                  Education
                </Link>
              </li>
              <li className="ml-1 hiddenBox">
                <Link
                  to="#"
                  onClick={() => setCategory("Landing Page")}
                  className={`${displayCategory === "Landing Page" ? "categories active" : "categories"} `}
                >
                  Landing Page
                </Link>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Categories;
