import React from "react";
import { Container, Row, Col } from "reactstrap";

// Import Blog Box
import CounterBox from "./counter-box";

// Import Images
import img2 from "../../assets/images/features/img-2.jpg";

const Counter = () => {
  const counters1 = [
    { icon: "mdi mdi-heart", title: "Happy Clients", end: 25 },
    { icon: "mdi mdi-layers", title: "Projects Completed", end: 30 },
  ];

  const counters2 = [
    { icon: "mdi mdi-cloud-download", title: "Files Downloaded", end: 150 },
    { icon: "mdi mdi-code-not-equal-variant", title: "Lines Of Code", end: 18450 },
  ];

  return (
    <>
      <section className="section bg-light">
        <Container>
          <Row className="vertical-content">
            <Col lg={6}>
              <div className="counter-box">
                <Col lg={12}>
                  <h4>Our Achievements</h4>
                  <p className="text-muted mt-2">
                    Delivering exceptional results, our achievements stand as a testament to our commitment to
                    excellence, innovation, and unwavering client satisfaction.
                  </p>
                  
                  <div style={{marginLeft:"-50px"}} className="mt-4">
                    <div className="d-flex justify-content-center gap-3" id="counter">
                      <CounterBox counters={counters1} />
                    </div>

                    <div className="d-flex justify-content-center gap-3" id="counter">
                      <CounterBox counters={counters2} />
                    </div>
                  </div>
                </Col>
              </div>
            </Col>

            <Col lg={6}>
              <div className="img">
                <img src={img2} className="img-fluid box-shadow-lg rounded" alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Counter;
