import React, { useState } from "react";
import { Link } from "react-router-dom";
import FadeIn from "react-fade-in";
import DailogBox from "./DailogBox";
import ViewImages from "./ViewImages";

const ProjectItem = ({ project }) => {
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <FadeIn>
        <div className="item-box">
          <Link
            to="#"
            onClick={(event) => {
              event.preventDefault();
              setShow(true);
            }}
            className="cbox-gallary1 mfp-image"
            title={project.title}
          >
            <img className="item-container rounded" src={project.image} alt="work-img" />
            <div className="item-mask">
              <div className="item-caption">
                <h5 className="f-18">{project.title}</h5>
                <p className="text-primary">{project.subtitle}</p>
              </div>
            </div>
          </Link>
        </div>
      </FadeIn>

      <DailogBox show={show} Hide={() => setShow(false)} project={project} setIsOpen={setIsOpen} />
      <ViewImages project={project} isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default ProjectItem;
