import React from "react";
import map from "../../assets/images/features/map.png";
import { Col, Media } from "reactstrap";
import ScrollAnimation from "../../utils/Animation/ScrollAnimation";

const SideBar = () => {
  return (
    <>
      <ScrollAnimation observeClass="hiddenBox" animationClass="showBox" />
      <Col lg={12}>
        <div className="contact-info">
          <div 
            style={{ 
              backgroundImage: `url(${map})`, 
              backgroundRepeat: "no-repeat", 
              display: "flex",
              flexWrap: "wrap", 
              padding: "20px",
              justifyContent: "center",
              gap:"10px",
              marginLeft:"3em"
            }}
          >
            <div>
            <Media className="hiddenBox processBox d-flex" style={
              {
                width:"320px"
              }
            }>
              <i className="mdi mdi-map-marker text-primary h4 me-1"></i>
              <Media body className="ml-2">
                <p className="text-muted">
                  Karachi, Sindh Pakistan Currently operating Remotely
                </p>
              </Media>
            </Media>

            <Media className="hiddenBox processBox d-flex">
              <i className="mdi mdi-phone text-primary me-1 h4"></i>
              <Media body className="ml-2">
                <p className="text-muted">Phone: +923111352377</p>
              </Media>
            </Media>
            </div>

            <div>
            <Media className="hiddenBox processBox d-flex" style={
              {
                width:"320px"
              }
            }>
              <i className="mdi mdi-calendar-clock text-primary me-1 h4"></i>
              <Media body className="ml-2">
                <p className="text-muted">Hours: Available from 9 am to 12 am (UTC+5)</p>
              </Media>
            </Media>

            <Media className="hiddenBox processBox d-flex">
              <i className="mdi mdi-email text-primary me-1 h4"></i>
              <Media body className="ml-2">
                <p className="text-muted">Email: wajidmaitlo124@gmail.com</p>
              </Media>
            </Media>
            </div>
          </div>
        </div>
      </Col>
    </>
  );
};

export default SideBar;
