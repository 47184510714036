import React from "react";
import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const ViewImages = ({ isOpen, project, setIsOpen }) => {
  return (
    <>
      <Lightbox
        plugins={[Zoom, Thumbnails, Fullscreen, Captions]}
        open={isOpen}
        close={() => setIsOpen(false)}
        slides={project.slider.map((slide) => ({ src: slide.src, description: slide.title }))}
      />
    </>
  );
};

export default ViewImages;
