import React from "react";
import { Col } from "reactstrap";

const PricingBox = ({ pricings }) => {
  return (
    <>
      {pricings.map((pricing, key) => (
        <Col lg={4} key={key}>
          <div className="pricing-box bg-white box-shadow text-center p-5 mt-4 rounded">
            {pricing.isNew === true && (
              <div className="pricing-label">
                <h5 className="text-white f-16">New</h5>
              </div>
            )}
            <h5 className="text-uppercase">{pricing.title}</h5>
            <h1 className="mt-4 pt-2">
              <sup className="h3">$</sup> {pricing.price}
            </h1>
            <div className="pricing-features mt-4 pt-3">
              {pricing.features.map((feature, key) => (
                <p key={key}>{feature.title}</p>
              ))}
            </div>
            <div className="mt-5">
              <a href=" https://wa.me/923111352377" rel="noreferer" target="_blank" className="btn btn-primary w-100">
                Buy Now
              </a>
            </div>
          </div>
        </Col>
      ))}
    </>
  );
};

export default PricingBox;
