import React from "react";
import { Modal } from "react-bootstrap";

const DailogBox = ({ show, Hide, project, setIsOpen }) => {
  return (
    <>
      <Modal show={show} onHide={Hide} size="xl" className="product-quickview-modal-wrapper">
        <Modal.Header closeButton>
          <b>{project.title}</b>
        </Modal.Header>

        <div className="modal-body modal-style">
          <div className="row">
            <div className="col-md-5 col-sm-12 col-xs-12">
              <img className="item-container rounded" src={project.image} alt="work-img" />
              <div className="dailog-btns">
                <button onClick={() => setIsOpen(true)} className="btn btn-primary mr">
                  View Here
                </button>
                {project.Url ? (
                  <a href={project.Url} rel="noreferrer" target="_blank" className="btn btn-start">
                    Go to Website
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-md-7 col-sm-12 col-xs-12">
              <h4>{project.title} Specifications</h4>
              <ul>
                {project.description.map((desc, index) => {
                  return (
                    <li key={index}>
                      <i class="mdi mdi-arrow-right-thick text-primary"></i> {desc.description}
                    </li>
                  );
                })}
                {project.links && (
                  <li>
                  <h4>Click below to see different Pages</h4>
                  {project.links.map((link, index) => {
                    return (
                      <a key={index} href={link.url} rel="noreferrer" target="_blank" style={{marginTop:"10px"}} className="btn btn-primary mr">
                        {link.title}
                      </a>
                    );
                  })}
                  </li>
                )}
                <li style={{ marginTop: "40px" }}>
                  <b>Note: View Here</b> only displays a few pages for efficiency. Click <b>Go to Website</b> to see the full version.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DailogBox;
