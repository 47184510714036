import React, { useState } from "react";
import { Container, Col } from "reactstrap";
import projects_data from "./projects.json";

// Import Section Title
import SectionTitle from "../common/section-title";
import Categories from "./Categories";
import ProjectItem from "./ProjectItem";

const Portfolio = () => {
  const [projects] = useState(projects_data);
  const [displayCategory, setDisplayCategory] = useState("All");

  return (
    <section className="section" id="portfolio" path="/portfolio">
      <Container>
        <SectionTitle
          title="Our Works"
          subtitle="Portfolio"
          desc="Explore our portfolio: a showcase of meticulously crafted projects embodying innovation, functionality, and exceptional design in every detail."
        />
        {/* Categories */}
        <Categories setCategory={setDisplayCategory} displayCategory={displayCategory} />

        <div className="port portfolio-masonry mt-5">
          <div className="portfolioContainer row">
            {projects
              .filter(({ category }) => displayCategory === category || displayCategory === "All")
              .map((project, index) => (
                <Col lg={4} md={4} key={index} className="p-3">
                  <ProjectItem project={project} />
                </Col>
              ))}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Portfolio;
