import React from "react";
import { Container, Row } from "reactstrap";

// Import Section Title
import SectionTitle from "../common/section-title";
import AboutBox from "./about-box";
import BestSkills from "./bestSkills";
import ScrollAnimation from "../../utils/Animation/ScrollAnimation";
import SEO from "../seo";

const About = () => {
  const data = [
    {
      icon: "mdi mdi-monitor",
      title: "Web design",
      desc: "Crafting visually stunning websites with a focus on user experience.",
    },
    {
      icon: "mdi mdi-nfc-variant",
      title: "Development",
      desc: "Building robust and scalable web solutions for seamless digital experiences.",
      isLight: true,
    },
    {
      icon: "mdi mdi-lightbulb-on-outline",
      title: "Branding",
      desc: "Crafting unique brand identities for a lasting and impactful impression.",
    },
  ];

  return (
    <>
      <SEO titleTemplate={"About"} description={"DevSiter builds lightning-fast Next js and React websites, outperforming slow platforms. Led by Wajid Ali, we enhance your online presence with speed and efficiency."} />
      <ScrollAnimation observeClass="hiddenAnimation" animationClass="showAnimation" />
      <section className="section" id="about">
        <Container>
          <SectionTitle
            title="About US"
            subtitle="WHO WE ARE?"
            desc="At DevSiter, we noticed a common problem: many websites built on platforms like Shopify and WordPress lack speed ⚡. In today's digital age, speed is crucial for attracting customers. That's why we specialize in crafting lightning-fast websites using Next js and React 💻. Our goal? To make your website smooth and efficient, without breaking the bank 💰. Let us elevate your online presence and drive results 🚀."
          />
          <BestSkills />
          <Row className="mt-5 pt-2">
            <AboutBox data={data} />
          </Row>
        </Container>
      </section>
    </>
  );
};

export default About;
