import React from "react";
import { Row, Col } from "reactstrap";

const SectionTitle = ({ subtitle, isLight, title, desc }) => {
  return (
    <>
      <Row>
        <Col lg="12">
          <div className="header-title text-center hiddenAnimation">
            {subtitle && <p className="text-uppercase text-muted mb-2">{subtitle}</p>}
            <h2 className={isLight === true ? "text-uppercase text-white" : "text-uppercase"}>{title}</h2>
            <div className="title-border mt-3"></div>
            <p className={isLight === true ? "title-desc text-white-50 mt-3" : "title-desc text-muted mt-3"}>{desc}</p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SectionTitle;
