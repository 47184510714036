import React, { useState, useEffect } from "react";
import Process from "../components/Process/process";
import NavbarPage from "../components/Navbar/Navbar_Page";
import Section from "./section";
import Portfolio from "../components/Portfolio/portfolio";
import Footer from "../components/Footer/footer";
import Pricing from "../components/Pricing/pricing";
import ScrollAnimation from "../utils/Animation/ScrollAnimation";
import SEO from "../components/seo";
import Clients from "../components/Testimonials/testimonials";
import Counter from "../components/Counter/counter";

const Index1 = () => {
  const [navItems] = useState([
    { id: 1, link: "/", navheading: "Home" },
    { id: 2, link: "/about", navheading: "About" },
    { id: 5, link: "/contact", navheading: "Contact" },
  ]);

  const [pos] = useState(0);
  const [imglight, setImgLight] = useState(true);
  const [navClass, setNavClass] = useState("");

  const scrollNavigation = () => {
    const scrollup = document.documentElement.scrollTop;
    if (scrollup > pos) {
      setNavClass("nav-sticky");
      setImgLight(false);
    } else {
      setNavClass("");
      setImgLight(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
    return () => {
      window.removeEventListener("scroll", scrollNavigation, true);
    };
  });

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("showAnimation");
          // observer.unobserve(entry.target); // To ensure the animation triggers only once
        }
      });
    });

    const hiddenElements = document.querySelectorAll(".hiddenAnimation");
    hiddenElements.forEach((el) => observer.observe(el));

    return () => {
      hiddenElements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  return (
    <>
      <SEO />
      <ScrollAnimation observeClass="hiddenAnimation" animationClass="showAnimation" />
      <NavbarPage navItems={navItems} navClass={navClass} imglight={imglight} />
      <Section />
      <Portfolio />
      <Clients />
      <Counter />
      <Process />
      <Pricing />
      <Footer />
    </>
  );
};

export default Index1;
