import React from "react";
import { Link } from "react-router-dom";

const LinkSection = ({ title, links }) => {
  return (
    <>
      <h6 className="text-yellow text-uppercase f-16">{title}</h6>
      <ul className="list-unstyled footer-link mt-3 mb-0">
        {links.map((fLink, key) => (
          <li key={key}>
            <Link to={fLink.link}><i className={fLink.icon}></i>{' '} {fLink.title}</Link>
          </li>
        ))}
      </ul>
    </>
  );
};

export default LinkSection;
