import React from "react";
import { Container, Row } from "reactstrap";

// Import Section Title
import SectionTitle from "../common/section-title";

// Import Images

import SideBar from "./SideBar";
import ScrollAnimation from "../../utils/Animation/ScrollAnimation";
import SEO from "../seo";

const GetInTouch = () => {
  return (
    <React.Fragment>
      <SEO titleTemplate={"Contact"} description={"Get in touch with DevSiter for fast, efficient web solutions. Available remotely in Karachi, 9 AM – 12 AM (UTC+5)."} />
      <section className="section" id="contact">
        <ScrollAnimation observeClass="hiddenAnimation" animationClass="showAnimation" />
        <Container>
          <SectionTitle
            title="Get In Touch"
            subtitle="Contact"
            desc="Have a project in mind or simply want to learn more about how DevSiter can help elevate your online presence? We'd love to hear from you! Get in touch with us today to discuss your web development needs, request a quote, or inquire about our services."
          />
          <Row className="mt-5 pt-2">
            <SideBar />
          </Row>

          <div style={{
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          }}>
          <div className="pt-4 mt-1 ">
                <a href=" https://wa.me/923111352377" rel="noreferer" target="_blank" className="btn btn-purchase">
                  Chat on WhatsApp
                </a>
            </div>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default GetInTouch;
