import React from "react";
import { Container, Row, Col, Media } from "reactstrap";

// Import Footer link
import FooterLinks from "./footer-links";
import LinkSection from "./link-section";

const Footer = () => {
  const state = {
    links1: [
      { link: "#", title: "Monitoring Grader" },
      { link: "#", title: "Video Tutorial" },
      { link: "#", title: "Term & Service" },
      { link: "#", title: "Tulsy API" },
    ],
    links2: [
      { link: "#", title: "Phone: +923111352377" },
      { link: "#", title: "Email: wajidmaitlo124@gmail.com" },
      { link: "#", title: "Hours: Available from 9 am to 12 am (UTC+5)" },
    ],
    links3: [
      { link: "https://www.facebook.com/profile.php?id=61573415634305", title: "Facebook",icon: "mdi mdi-facebook" },
      { link: "https://www.linkedin.com/company/devsiter/", title: "LinkedIn",  icon: "mdi mdi-linkedin"},
      { link: "https://www.instagram.com/devsiter/", title: "Instagram",icon: "mdi mdi-instagram" },
    ],
  };

  return (
    <>
      <footer className="bg-footer">
        <Container>
          <Row className="py-5">
            <Col lg={3}>
              <div className="mt-2">
                <h6 className="text-yellow text-uppercase f-16">DevSiter</h6>
                <p className="text-white-50 mt-3 pt-2 mb-0 ">
                  Delivering cutting-edge web solutions leveraging the power of React, Node.js, Express, and MongoDB.
                </p>
              </div>
            </Col>

            <Col lg={2}>
              <div className="mt-2 pl-0 pl-lg-5">
                <LinkSection title="CONNECT WITH US" links={state.links3} target="_blank" />
              </div>
            </Col>

            <Col lg={3}>
              <div className="mt-2">
                <h6 className="text-yellow text-uppercase f-16">Location</h6>
                <div className="mt-3 mb-0">
                  <div className="">
                    <Media>
                      <Media body>
                        <p className="text-white-50 pl-3">
                          DevSiter is headquartered in Karachi, Sindh Province, Pakistan.
                        </p>
                      </Media>
                    </Media>
                  </div>
                  <div className="mt-2">
                    <Media>
                      <Media body>
                        <p className="text-white-50 pl-3">Currently operating remotely.</p>
                      </Media>
                    </Media>
                  </div>
                </div>
              </div>
            </Col>

            <Col lg={2}>
              <div className="mt-2">
                <LinkSection title="Contact" links={state.links2} />
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
      {/* Render footer links */}
      <FooterLinks />
    </>
  );
};

export default Footer;
