import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";

// Import Components
import ProgressBar from "./progressbar";

// Import Images
import features1 from "../../assets/images/features/img-1.png";

const BestSkills = () => {
  const skillsData = [
    { title: "Web Design", value: 85 },
    { title: "Frontend (Next js, React)", value: 80 },
    { title: "Backend (Node.js, Express)", value: 70 },
    { title: "Database (MongoDB)", value: 65 },
    { title: "State Management (Zustand, Redux)", value: 75 },
  ];

  useEffect(() => {
    const ele = document.getElementsByClassName("progress-bar");
    for (let i = 0; i < ele.length; i++) {
      ele[i].classList.add("rounded");
    }
  }, []);

  return (
    <>
      <section className="section bg-light">
        <Container>
          <Row className="vertical-content">
            <h3>Meet Mr. Wajid Ali</h3>
            <p>the visionary Founder and Lead Developer behind DevSiter.</p>
            <Col lg="6" className="p-0">
              <div className="skill-box bg-white p-4 rounded box-shadow">
                <h5>Wajid Ali</h5>
                <p className="text-muted">skills</p>
                <div className="skills">
                  <ProgressBar skills={skillsData} />
                </div>
              </div>
            </Col>
            <Col lg="6" className="p-0 about-img">
              <img src={features1} className="img-fluid box-shadow" alt="Wajid Ali" />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default BestSkills;
