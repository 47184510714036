import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Carousel, CarouselItem, CarouselControl } from "reactstrap";

// Import Section Title
import SectionTitle from "../common/section-title";

// Import Images
import img1 from "../../assets/images/users/img-1.jpg";
import img2 from "../../assets/images/users/img-2.jpg";
import img3 from "../../assets/images/users/img-3.jpg";

const Clients = () => {
    const items = [
      {
        id: 1,
        image: img1, 
        title: "Sajjad Ali",
        subtitle: "Mobile App Founder",
        desc: "I needed a landing page for my mobile app, and Wajid Ali at Devsiter delivered a professional, high-performance design. It’s user-friendly and exactly what I envisioned!"
      },
        {
          id: 2,
          image: img3, 
          title: "Mike",
          subtitle: "Founder of Top fived",
          desc: "Wajid Ali (Founder of Devsiter) developed my social media app with all the essential features like followers, competitions, and rankings. The execution was flawless, and the app functions smoothly. Highly recommended!"
        },
        {
          id: 3,
          image: img2, 
          title: "Nabeel Rajput",
          subtitle: "Creative Designer",
          desc: "Wajid Ali crafted my portfolio with a sleek, modern design and animations that reflect my creative style. The result was outstanding and far beyond my expectations!"
        },
        {
          id: 4,
          image: img3, 
          title: "Arun Kumar",
          subtitle: "Banking & Notices Automation",
          desc: "I needed an automated system for sending bank notices via WhatsApp, and Wajid Ali at Devsiter built the perfect solution. The website generates PDFs based on templates and delivers them efficiently. A game-changer!"
        },
      ];
      

  const [activeIndex, setActiveIndex] = useState(0);
  const animating = useRef(false);

  const onExiting = () => {
    animating.current = true;
  };

  const onExited = () => {
    animating.current = false;
  };

  const next = () => {
    if (animating.current) return;
    setActiveIndex((prevIndex) => (prevIndex === items.length - 1 ? 0 : prevIndex + 1));
  };

  const previous = () => {
    if (animating.current) return;
    setActiveIndex((prevIndex) => (prevIndex === 0 ? items.length - 1 : prevIndex - 1));
  };

  useEffect(() => {
    const e1 = document.getElementsByClassName("carousel-indicators");
    if (e1.length > 0) {
      const e2 = e1[0].getElementsByTagName("li");
      items.forEach((item, index) => {
        const img = document.createElement("img");
        img.className = "testi-img img-fluid rounded mx-auto d-block";
        img.src = item.image;
        if (e2[index]) e2[index].appendChild(img);
      });
    }
  });

  return (
    <section className="section bg-testimonial" id="testimonial">
      <div className="bg-overlay"></div>
      <Container>
        <SectionTitle
          title="TESTIMONIALS"
          desc="See what our clients say about their experience with Devsiter. From innovative solutions to seamless execution, we take pride in delivering excellence."
          isLight={true}
        />
        
        <Row className="mt-5 pt-2">
          <Col lg={12}>
            <Carousel activeIndex={activeIndex} next={next} previous={previous}>
              {items.map((item, key) => (
                <CarouselItem key={key} onExiting={onExiting} onExited={onExited}>
                  <div className="testimonial-box text-center mt-4">
                    <img src={item.image} width={100} height={100} className="m-md-4 rounded" alt="" />
                    <div className="testimonial-title mt-3 p-2 rounded">
                      <p className="text-white mt-4">{item.desc}</p>
                    </div>
                    <i className="mdi mdi-format-quote-close text-primary display-3"></i>
                    <h5 className="text-white f-18">{item.title}</h5>
                    <p className="text-white-50 mb-0">{item.subtitle}</p>
                  </div>
                </CarouselItem>
              ))}
              <CarouselControl direction="prev" onClickHandler={previous} />
              <CarouselControl direction="next" onClickHandler={next} />
              
            </Carousel>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Clients;
