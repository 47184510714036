import React, { useEffect, useState } from "react";
import { Navbar, Nav, NavbarBrand, NavbarToggler, NavItem, Container, Collapse } from "reactstrap";
import logolight from "../../assets/images/logo-light.png";
import logodark from "../../assets/images/logo-dark.png";
import { Link } from "react-router-dom";

const NavbarPage = ({ navItems, navClass, imglight }) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const toggle = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  // Store all Navigationbar Id into TargetID variable (Used for Scrollspy)

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <React.Fragment>
      <Navbar expand="lg" fixed="top" className={"navbar-custom sticky sticky-dark " + navClass}>
        <Container>
          <NavbarToggler className="navbar-toggler" onClick={toggle}>
            <i className="mdi mdi-menu"></i>
          </NavbarToggler>

          <Collapse id="navbarCollapse" isOpen={isOpenMenu} navbar>
            <NavbarBrand className="logo nav-logo text-uppercase " href="/">
              {imglight ? (
                <img
                  src={isMobile ? logodark : logolight}
                  alt=""
                  className={isMobile ? "logo-dark" : "logo-light"}
                  height="22"
                />
              ) : (
                <img src={logodark} alt="" className="logo-dark" height="22" />
              )}
            </NavbarBrand>
            <div className="navbar-collapse navItems">
              <Nav navbar className="d-flex justify-content-end" id="mySidenav">
                {navItems.map((item, key) => (
                  <NavItem key={key}>
                    <Link to={item.link} className={""}>
                      {item.navheading}
                    </Link>
                  </NavItem>
                ))}
              </Nav>
            </div>
          </Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
};

export default NavbarPage;
