import { useEffect } from "react";

const ScrollAnimation = ({ observeClass, animationClass }) => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(animationClass);
          observer.unobserve(entry.target); // To ensure the animation triggers only once
        }
      });
    });

    const hiddenElements = document.querySelectorAll(`.${observeClass}`);
    hiddenElements.forEach((el) => observer.observe(el));

    return () => {
      hiddenElements.forEach((el) => observer.unobserve(el));
    };
  }, [observeClass, animationClass]);

  return null; // This component doesn't render anything
};

export default ScrollAnimation;
