import React from "react";
import { Col } from "reactstrap";
import CountUp from "react-countup";

const CounterBox = ({ counters }) => {
  return (
    <>
      {counters.map((counter, key) => (
        <Col lg={4} key={key}>
          <div style={{width:"150px"}} className="counter-box d-flex flex-column align-content-center mt-4">
            <div className="counter-icon">
              <i className={counter.icon}></i>
            </div>

            <h4 className="counter-value text-center">
              <CountUp duration={8} end={counter.end} />
            </h4>
            <p className="text-muted text-center">{counter.title}</p>
          </div>
        </Col>
      ))}
    </>
  );
};

export default CounterBox;
