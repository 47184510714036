import React from "react";
import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Import Scss
import "./theme.scss";

//Import Icon Css
import "./assets/css/materialdesignicons.min.css";
import Index1 from "./pages/Index1";
import Layout from "./components/Layout/Layout";
import About from "./components/About/about";
import GetInTouch from "./components/GetInTouch/GetInTouch";

const ScrollToTop = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function App() {
  return (
    <React.Fragment>
      <ToastContainer />
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path={process.env.PUBLIC_URL + "/"} element={<Index1 />} />
        </Routes>
        <Routes>
          <Route
            path={process.env.PUBLIC_URL + "/about"}
            element={
              <Layout>
                <About />
              </Layout>
            }
          />
        </Routes>
        <Routes>
          <Route
            path={process.env.PUBLIC_URL + "/contact"}
            element={
              <Layout>
                <GetInTouch />
              </Layout>
            }
          />
        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;
